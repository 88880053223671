import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["operatorSelector", "minValue", "maxValue"];

  connect() {
    this.initialMiniValueInput();
  }

  initialMiniValueInput() {
    this.toggleMinValueInput(this.operatorSelectorTarget.value);
    this.updateInputStyle(this.operatorSelectorTarget.value);
  }

  operatorSelectorHandler(e) {
    this.toggleMinValueInput(e.target.value);
    this.updateInputStyle(e.target.value);
  }

  toggleMinValueInput(operator) {
    if (operator === "~") {
      this.minValueTarget.classList.remove("hidden");
    } else {
      this.minValueTarget.classList.add("hidden");
      this.minValueTarget.value = "";
    }
  }

  updateInputStyle(operator) {
    if (operator === "~") {
      this.minValueTarget.classList.remove("hidden");
      // Make max value input full width
      this.maxValueTarget.classList.remove("w-16");
      this.maxValueTarget.classList.add("w-full");
    } else {
      this.minValueTarget.classList.add("hidden");
      // Make max value input width 16 rem
      this.maxValueTarget.classList.add("w-16");
      this.maxValueTarget.classList.remove("w-full");
      this.minValueTarget.value = "";
    }
  }

  inputValidation(e) {
    var target = e.target;
    if (
      target === this.minValueTarget &&
      (this.maxValueTarget.value !== "" &&
        parseFloat(target.value) > parseFloat(this.maxValueTarget.value))
    ) {
      target.value = this.maxValueTarget.value;
    } else if (
      target === this.maxValueTarget &&
      (this.minValueTarget.value !== "" &&
        parseFloat(target.value) < parseFloat(this.minValueTarget.value))
    ) {
      target.value = this.minValueTarget.value;
    }
  }
}
