import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "resetInstancesCheckBox"]
  static values = {
    confirmMessage: String,
  }

  submit(event) {
    if (this.resetInstancesCheckBoxTarget.checked) {
      const confirmation = confirm(this.confirmMessageValue);
      if (!confirmation) event.preventDefault();
    }
  }
}