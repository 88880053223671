import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "figureRendering",
    "attributeRendering",
    "resultAttributes",
    "resultIndex",
    "showPageLinkRendering",
    "thumbnail"
  ];

  static values = {
    results: Array,
    attributeSlugToName: Object,
    offset: Number,
  };

  showResult(e) {
    this.removeExistingResult();
    this.renderResult(e.params);
    this.updateThumbnailStyle(e.target);
  }

  updateThumbnailStyle(target) {
    this.thumbnailTargets.forEach((thumbnailTarget) => { 
      thumbnailTarget.classList.remove("border-2");
      thumbnailTarget.classList.add("opacity-50");
    })
    if (target.tagName === "FIGURE") {
      if (target.getElementsByTagName("img").length > 0)
        [...target.getElementsByTagName("img")].forEach((imgTag) => {
          imgTag.classList.add("border-2", "border-gray-300", "box-border")
          imgTag.classList.remove("opacity-50");
        });
      if (target.getElementsByTagName("div").length > 0)
        [...target.getElementsByTagName("div")].forEach((divTag) => {
          divTag.classList.add("border-2", "border-gray-300", "box-border")
          divTag.classList.remove("opacity-50");
        });
    } else {
      target.classList.add("border-2", "border-gray-300", "box-border");
      target.classList.remove("opacity-50");
    }
  }

  removeExistingResult() {
    if (this.figureRenderingTarget.getElementsByTagName("img").length > 0)
      [...this.figureRenderingTarget.getElementsByTagName("img")].forEach(
        (imgTag) => imgTag.remove()
      );
    if (this.figureRenderingTarget.getElementsByTagName("div").length > 0)
      [...this.figureRenderingTarget.getElementsByTagName("div")].forEach(
        (divTag) => divTag.remove()
      );
    if (this.figureRenderingTarget.getElementsByTagName("iframe").length > 0)
      [...this.figureRenderingTarget.getElementsByTagName("iframe")].forEach(
        (iframeTag) => iframeTag.remove()
      );
    if (this.attributeRenderingTargets.length > 0)
      this.attributeRenderingTargets.forEach((attributeRenderingTarget) =>
        attributeRenderingTarget.remove()
      );
  }

  updateShowPageLink(url) {
    this.showPageLinkRenderingTarget.href = url;
  }

  renderResult(params) {
    const result = this.resultsValue.find(
      (result) => result.id === params.resultId
    );
    this.appendImage(params);
    this.updateShowPageLink(params.showPageUrl);
    this.appendAttributes(result);
    this.updateResultIndex(
      this.resultsValue.findIndex(
        (resultValue) => resultValue.id === result.id
      ) +
        1 +
        this.offsetValue
    );
  }

  appendImage(params) {
    var img = document.createElement("img");
    img.src = params.imageUrl;
    img.classList.add("max-h-[40rem]", "m-auto");
    this.figureRenderingTarget.appendChild(img);
  }

  updateResultIndex(index) {
    this.resultIndexTarget.innerText = index;
  }
};
