import largeModalBase from "./large_modal_base";
export default class extends largeModalBase {
  appendAttributes(result) {
    ["file-name", "file-extension", "file-from"].forEach((attributeSlug) => {
      // Compose attribute row
      var attributeRow = document.createElement("div");
      attributeRow.classList.add(
        "div-table-row",
        "text-center",
        "bg-white",
        "border-b-2",
        "border-slate-50",
        "h-20"
      );
      attributeRow.dataset.largeFileModalTarget = "attributeRendering";
      // Compose attribute name
      var attributeNameColumn = document.createElement("div");
      attributeNameColumn.innerText =
        this.attributeSlugToNameValue[attributeSlug];
      attributeRow.appendChild(attributeNameColumn);
      // Compose attribute value
      var attributeValue = document.createElement("div");
      attributeValue.innerText = result.file_data[attributeSlug];
      attributeValue.classList.add("w-20", "break-all");
      attributeRow.appendChild(attributeValue);
      // Append to attribute table
      this.resultAttributesTarget.appendChild(attributeRow);
    });
  }

  appendImage(params) {
    if (params.isRenderable) {
      var img = document.createElement("img");
      img.src = params.imageUrl;
      this.figureRenderingTarget.appendChild(img);
    } else if (params.isPdf) {
      var iframe = document.createElement("iframe");
      iframe.src = params.imageUrl;
      iframe.width = "100%";
      iframe.height = "700";
      this.figureRenderingTarget.appendChild(iframe);
    } else {
      var div = document.createElement("div");
      div.innerHTML = params.fileExtension;
      div.classList.add(
        "text-gray-300",
        "text-5xl",
        "h-full",
        "uppercase",
        "pt-14",
        "font-bold",
        "text-center"
      );
      this.figureRenderingTarget.appendChild(div);
    }
  }
}
