import Sortable from "stimulus-sortable";

export default class extends Sortable {
  static targets = ['position']

  connect() {
    super.connect()
    this.updatePositions()
  }

  // You can override the `end` method here.
  end () {
    this.updatePositions()
  }

  updatePositions() {
    this.positionTargets.forEach((element, index) => {
      element.value = index + 1
    })
  }
}