import { Controller } from "@hotwired/stimulus";
const wordSplitor = ", ";
export default class extends Controller {
  static targets = [
    "searchGroupSwitch",
    "searchFields",
    "booleanSelector",
    "dateOperatorSelector",
    "dateValue",
    "numberOperatorSelector",
    "numberValue",
    "textOperatorSelector",
    "textValue"
  ];
  static values = {
    searchGroupInfo: Object,
  };

  connect() {
    this.initializeSearchGroup();
  }

  toggleSearchfields() {
    this.searchFieldsTarget.classList.toggle("hidden");
  }

  initializeSearchGroup() {
    if (this.searchGroupInfoValue.is_apply === "true") {
      this.searchGroupSwitchTarget.checked = true;
      this.searchFieldsTarget.classList.remove("hidden");
      this.searchGroupInfoValue.search_conditions.forEach((searchCondition) => {
        this.initializeSearchCondition(searchCondition);
      });
    }
  }

  initializeSearchCondition(searchCondition) {
    Object.entries(searchCondition).forEach(([_, info]) => {
      switch (info.type) {
        case "number":
          this.initializeNumberField(info);
          break;
        case "date":
          this.initializeDateField(info);
          break;
        case "boolean":
          this.initializeBooleanField(info);
          break;
        case "text":
          this.initializeTextField(info);
          break;
      }
    });
  }

  initializeNumberField(info) {
    const targetSelector = this.numberOperatorSelectorTargets.find(
      (target) => target.id === `${info.slug}-operator`
    );
    targetSelector.value = info.operator
    const firstTargetValue = this.numberValueTargets.find(
      (target) => target.id === `${info.slug}-values-first`
    );
    const secondTargetValue = this.numberValueTargets.find(
      (target) => target.id === `${info.slug}-values-second`
    );
    firstTargetValue.value = info.values[0];
    secondTargetValue.value = info.values[1];
  }

  initializeDateField(info) {
    const targetSelector = this.dateOperatorSelectorTargets.find(
      (target) => target.id === `${info.slug}-operator`
    );
    targetSelector.value = info.operator
    const firstTargetValue = this.dateValueTargets.find(
      (target) => target.id === `${info.slug}-values-first`
    );
    const secondTargetValue = this.dateValueTargets.find(
      (target) => target.id === `${info.slug}-values-second`
    );
    firstTargetValue.value = info.values[0];
    secondTargetValue.value = info.values[1];
    this.initializeDateInputStyle();
  }

  initializeDateInputStyle() {
    this.dateValueTargets.forEach((dateValueTarget) => {
      if (dateValueTarget.value !== "") {
        dateValueTarget.style.color = "black";
      } else {
        dateValueTarget.removeAttribute("style");
      }
    });
  }

  initializeBooleanField(info) {
    const targetSelector = this.booleanSelectorTargets.find(
      (target) => target.id === `${info.slug}-value`
    );
    targetSelector.value = info.value
  }

  initializeTextField(info) {
    const targetSelector = this.textOperatorSelectorTargets.find(
      (target) => target.id === `${info.slug}-operator`
    );
    targetSelector.value = info.operator;
    const targetValue = this.textValueTargets.find(
      (target) => target.id === `${info.slug}-value`
    );
    targetValue.value = info.value;
  }
}
