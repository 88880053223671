import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchResultInGrid", "searchResultInList"];
  static values = {
    listMode: String,
  };

  connect() {
    if (this.listModeValue === "grid") this.displayInGrid();
    if (this.listModeValue === "list") this.displayInList();
  }

  displayInGrid() {
    this.searchResultInGridTarget.classList.remove("hidden");
    this.searchResultInListTarget.classList.add("hidden");
  }

  displayInList() {
    this.searchResultInGridTarget.classList.add("hidden");
    this.searchResultInListTarget.classList.remove("hidden");
  }
}
