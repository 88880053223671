import { Controller } from "@hotwired/stimulus";
const wordSplitor = ", ";
export default class extends Controller {
  static targets = [
    "myDropdown",
    "textInput",
    "textOption",
    "searchInput",
    "noTextOption",
  ];
  static values = {
    organizationSlug: String,
    entityAttributeSlug: String,
    mutipleSelection: Boolean
  };

  connect() {
    this.addHideDropdownEvent();
  }

  addHideDropdownEvent() {
    document.body.addEventListener("click", (e) => {
      const target = e.target;
      if (
        target === this.searchInputTarget ||
        target === this.textInputTarget ||
        this.textOptionTargets.includes(target)
      )
        return;

      this.hideDropDown();
    });
  }

  showDropDown() {
    this.getTextOptions();
    this.myDropdownTarget.classList.remove("hidden");
    this.filterOptions();
  }

  hideDropDown() {
    this.myDropdownTarget.classList.add("hidden");
  }

  getTextOptions() {
    if (this.textOptionTargets.length !== 0) return;

    fetch(
      `/organizations/${this.organizationSlugValue}/search_bar/text_type_search_field_options?entity_attribute_slug=${this.entityAttributeSlugValue}`
    )
      .then((response) => response.json())
      .then(({ options }) => {
        options.forEach((option) => this.createTextOption(option));
        if (options.length > 0) {
          this.noTextOptionTarget.classList.add("hidden");
        } else {
          this.noTextOptionTarget.classList.remove("hidden");
        }
      });
  }

  createTextOption(optionName) {
    var li = document.createElement("li");
    var span = document.createElement("span");
    li.dataset.searchGroupTextTypeSearchFieldTarget = "textOption";
    li.dataset.action = this.liOptionAction();
    li.classList.add("w-full");
    span.appendChild(document.createTextNode(optionName));
    li.appendChild(span);
    this.myDropdownTarget.appendChild(li);
  }

  liOptionAction() {
    return this.mutipleSelectionValue
      ? "click->search-group-text-type-search-field#fillTextInput"
      : "click->search-group-text-type-search-field#overridenTextInput";
  }

  clearSearchInput() {
    this.searchInputTarget.value = "";
  }

  filterOptions() {
    const filter = this.searchInputTarget.value.toUpperCase();
    const selectedOptions = this.textInputTarget.value
      .split(wordSplitor)
      .map((word) => word.toUpperCase());
    this.textOptionTargets.forEach((textOption) => {
      const txtValue = (
        textOption.textContent || textOption.innerText
      ).toUpperCase();
      const matchSearchInput = txtValue.indexOf(filter) > -1;
      const inSelectedOptions = selectedOptions.some((selectedOption) => txtValue.includes(selectedOption));
      if (matchSearchInput && inSelectedOptions) {
        textOption.style.display = "";
      } else {
        textOption.style.display = "none";
      }
    });
  }

  fillTextInput(event) {
    if (this.textInputTarget.value) {
      this.textInputTarget.value =
        this.textInputTarget.value + wordSplitor + event.target.textContent;
    } else {
      this.textInputTarget.value = event.target.textContent;
    }
    this.textInputTarget.dispatchEvent(new Event("change"));
    this.hideDropDown();
    this.clearSearchInput();
  }

  overridenTextInput(event) {
    this.textInputTarget.value = event.target.textContent;
    this.textInputTarget.dispatchEvent(new Event("change"));
    this.hideDropDown();
    this.clearSearchInput();
  }
}
