import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "test", "resultPreviewSection"];

  static values = {
    organizationSlug: String,
  };

  getResultsPreview() {
    const formData = new FormData(this.formTarget);
    const csrfToken = document.querySelector("meta[name=csrf-token]").content;
    formData.set("authenticity_token", csrfToken);
    fetch(
      `/organizations/${this.organizationSlugValue}/instances/result_preview`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-CSRFToken": csrfToken,
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const searchResultsInfo = JSON.parse(data).search_results_info;
        if (searchResultsInfo.length > 0) {
          this.removeExistingDivs();
          searchResultsInfo.forEach((resultInfo) => {
            var div = document.createElement("div");
            div.innerHTML = `${resultInfo.name}: ${resultInfo.count} 件`;
            div.classList.add("mx-2");
            this.resultPreviewSectionTarget.appendChild(div);
          });
        }
      });
  }

  removeExistingDivs() {
    if (this.resultPreviewSectionTarget.getElementsByTagName("div").length > 0)
      [...this.resultPreviewSectionTarget.getElementsByTagName("div")].forEach(
        (divTag) => divTag.remove()
      );
  }

  updateDateInputStyle(e) {
    const target = e.target
    if (target.value !== "") {
      target.style.color = "black"
    } else {
      target.removeAttribute("style");
    }
  }
}
