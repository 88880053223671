import { Controller } from "@hotwired/stimulus";
const weightRangeDisplay = "initial";
export default class extends Controller {
  static targets = [
    "attributeSelector",
    "weightRange",
    "attributeType",
  ];

  connect() {
    this.initializeWeightRange();
    this.initializeAttributeType();
  }

  attributeSelectorChagned(event) {
    this.showWeightRange(event);
    this.updateAttributeType(event);
  }

  initializeWeightRange() {
    const selectedOptionAttributeType = [
      ...this.attributeSelectorTarget.options,
    ].find((option) => option.selected).dataset.attributeType;
    if (selectedOptionAttributeType !== "number") {
      this.weightRangeTarget.style.display = "none";
      this.weightRangeTarget.required = false;
    } else {
      this.weightRangeTarget.style.display = weightRangeDisplay;
      this.weightRangeTarget.required = true;
    }
  }

  initializeAttributeType() {
    const selectedOptionAttributeType = [
      ...this.attributeSelectorTarget.options,
    ].find((option) => option.selected).dataset.attributeType;
    if (selectedOptionAttributeType) {
      this.attributeTypeTarget.textContent =
        selectedOptionAttributeType[0].toUpperCase() +
        selectedOptionAttributeType.slice(1).toLowerCase();
    }
  }

  showWeightRange(event) {
    const attributeType =
      event.target.options[event.target.selectedIndex].dataset.attributeType;

    if (attributeType !== "number") {
      this.weightRangeTarget.style.display = "none";
      this.weightRangeTarget.required = false;
    } else {
      this.weightRangeTarget.style.display = weightRangeDisplay;
      this.weightRangeTarget.required = true;
    }
  }

  updateAttributeType(event) {
    const attributeType =
      event.target.options[event.target.selectedIndex].dataset.attributeType;
    if (attributeType) {
      this.attributeTypeTarget.textContent =
        attributeType[0].toUpperCase() + attributeType.slice(1).toLowerCase();
    }
  }
}
