import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="items-selecotr"
export default class extends Controller {
  changeItemsPerPage(e) {
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    searchParams.set("items", e.target.value);

    url.search = searchParams.toString();
    window.location = url.toString();
  }
}
