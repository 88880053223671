import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  static targets = [
    "addButton",
    "attributeSelector",
    "emptyRow",
    "attributeRow",
  ];
  static values = {
    entityAttributes: Array,
    duplicateAttributesErrorMessage: String,
  };

  add(event) {
    if (this.emptyRowTarget) {
      this.emptyRowTarget.style.display = "none";
    }
    super.add(event);
    this.disableAddButton();
  }

  remove(event) {
    super.remove(event);
    this.markDeletedAttributeRow(event);
    this.disableAddButton();
    if (this.validAttributeRowTargets().length === 0) {
      this.emptyRowTarget.style.display = "table-row";
    }
  }

  markDeletedAttributeRow(event) {
    event.target.closest(this.wrapperSelectorValue).dataset.destroyed = true;
  }

  disableAddButton() {
    if (
      this.validAttributeRowTargets().length >=
      this.entityAttributesValue.length
    ) {
      this.addButtonTarget.disabled = true;
    } else {
      this.addButtonTarget.disabled = false;
    }
  }

  validateDuplicateAttribute(event) {
    const arr = this.attributeSelectorTargets.map((attributeTarget) => {
      if (!attributeTarget.closest(this.wrapperSelectorValue).dataset.destroyed)
        return attributeTarget.value;
    });
    const duplicateElements = arr.filter(
      (item, index) => arr.indexOf(item) !== index
    ).filter((item) => item != undefined);

    if (duplicateElements.length > 0) {
      event.preventDefault();
      alert(this.duplicateAttributesErrorMessageValue);
    }
  }

  validAttributeRowTargets() {
    return this.attributeRowTargets.filter(
      (attributeRow) => !attributeRow.dataset.destroyed
    );
  }
}
