import largeModalBase from "./large_modal_base";
export default class extends largeModalBase {
  appendAttributes(result) {
    Object.keys(this.attributeSlugToNameValue).forEach((attributeSlug) => {
      // Compose attribute row
      var attributeRow = document.createElement("div");
      attributeRow.classList.add(
        "div-table-row",
        "text-center",
        "bg-white",
        "border-b-2",
        "border-slate-50"
      );
      attributeRow.dataset.largeImageModalTarget = "attributeRendering";
      // Compose attribute name
      var attributeNameColumn = document.createElement("div");
      attributeNameColumn.innerText =
        this.attributeSlugToNameValue[attributeSlug];
      attributeRow.appendChild(attributeNameColumn);
      // Compose attribute value
      var attributeValue = document.createElement("div");
      attributeValue.innerText = result.metadata[attributeSlug] || "";
      attributeValue.classList.add("w-20", "break-all");
      attributeRow.appendChild(attributeValue);
      // Append to attribute table
      this.resultAttributesTarget.appendChild(attributeRow);
    });
  }
}
